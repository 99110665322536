@use '@components/assets/styles/variables' as *;

// For desktop devices.
$ORANGE_BAR_HEIGHT: 128px;
$DISTANCE_FROM_ORANGE_BAR_TO_CONTENT: 106px;
$SUB_MENU_HEIGHT: 60px;

// For mobile devices.
$NAVBAR_HEIGHT_MOBILE: 60px;
$DISTANCE_FROM_NAV_TO_CONTENT: space();

.container {
  height: 60vh;

  @include media-breakpoint-up(xl) {
    height: 70vh;
  }
}

.list {
  margin-bottom: space();
}

.button {
  margin-right: rem(20px);

  & + .button {
    margin-top: rem(20px);
  }
}

.heading {
  @include media-breakpoint-up(md) {
    margin-bottom: space();
    font-size: rem(58px);
  }
}

.content {
  position: relative;
  z-index: z-index(base, 1);
  height: 100%;
  align-items: center;
  display: flex;
}

.innerContainer {
  width: 70%;

  @include media-breakpoint-up(xl) {
    width: 40%;
    max-width: 700px;
  }
}

.subTitle {
  font-weight: font-weight(semi);

  @include media-breakpoint-up(md) {
    font-size: rem(20px);
  }
}

.fadingBackground {
  position: absolute;
  opacity: 0.8;
  top: $NAVBAR_HEIGHT_MOBILE;
  background: linear-gradient(
    90deg,
    rgb(0 4 96 / 100%) 19%,
    rgb(0 4 96 / 50%) 48%,
    rgb(6 100 155 / 0%) 77%
  );
  width: 100%;
  height: calc(60vh + $DISTANCE_FROM_NAV_TO_CONTENT);
  margin: 0;

  @include media-breakpoint-up(md) {
    top: $ORANGE_BAR_HEIGHT;
    height: calc(60vh + $DISTANCE_FROM_ORANGE_BAR_TO_CONTENT);
  }

  @include media-breakpoint-up(xl) {
    height: calc(70vh + $DISTANCE_FROM_ORANGE_BAR_TO_CONTENT);
  }

  &.isReduced {
    @include media-breakpoint-up(md) {
      top: $ORANGE_BAR_HEIGHT;
      height: calc(
        60vh + $DISTANCE_FROM_ORANGE_BAR_TO_CONTENT - $SUB_MENU_HEIGHT
      );
    }

    @include media-breakpoint-up(xl) {
      height: calc(
        70vh + $DISTANCE_FROM_ORANGE_BAR_TO_CONTENT - $SUB_MENU_HEIGHT
      );
    }
  }
}

.image {
  z-index: -1;
  position: absolute;
  top: $NAVBAR_HEIGHT_MOBILE;
  width: 100%;
  object-fit: cover;
  height: calc(60vh + $DISTANCE_FROM_NAV_TO_CONTENT);
  margin: 0;

  @include media-breakpoint-up(md) {
    top: $ORANGE_BAR_HEIGHT;
    height: calc(60vh + $DISTANCE_FROM_ORANGE_BAR_TO_CONTENT);
  }

  @include media-breakpoint-up(xl) {
    height: calc(70vh + $DISTANCE_FROM_ORANGE_BAR_TO_CONTENT);
  }
}
