@use 'assets/styles/variables' as *;

.row {
  padding: space(lg) 0;

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-height: map-get($height-breakpoints, md)) {
    padding: space(xxxl) 0;
  }

  &:first-child {
    padding-top: 0;
  }
}

.reducedPadding {
  padding: space(lg) 0;

  &:first-child {
    padding-top: 0;
  }
}

.noPadding {
  padding: 0;
}

.zebra {
  .row:nth-of-type(odd) {
    background-color: transparent;
  }

  .row:nth-of-type(even) {
    background: color(background);
  }
}

.noZebra {
  .row {
    background-color: inherit;
  }

  .row:nth-of-type(odd) {
    background-color: inherit;
  }

  .row:nth-of-type(even) {
    background-color: inherit;
  }
}

.zebraRow {
  background-color: color(background) !important;
}

.noZebraRow {
  background-color: transparent !important;
}

.title {
  @include media-breakpoint-up(md) {
    max-width: 80%;
  }
}

.breadcrumbs {
  margin-bottom: space();

  @include media-breakpoint-up(md) {
    max-width: 40%;
  }
}
