@use '@components/assets/styles/variables' as *;

.date {
  font-size: font-size();
  color: color(tertiary, dark);
  margin-top: auto;
}

.card {
  display: flex;
  text-decoration: none;
  flex-direction: column;

  &:hover {
    text-decoration: none;

    .date {
      text-decoration: none;
    }

    .title {
      text-decoration: underline;
    }

    .content {
      text-decoration: none;
      color: color(tertiary, contrast);
    }
  }
}

.wienenergieStudy {
  width: rem(223px);
  height: rem(50px);
  background-color: color(primary);
  border-radius: 5px;
  box-shadow: 0 5px 15px -5px rgb(0 0 0 / 35%);
  transform: translate(-16px, 16px);
  position: absolute;
  top: 0;
  right: 0;

  img {
    width: 100%;
    padding: space(xs);
  }
}

.content {
  color: color(tertiary, contrast);

  @include limitLines(5);

  hyphens: auto;
}

.category {
  line-height: 1.5;
  letter-spacing: normal;
  color: #07112a;
  font-size: 16px;
  margin-bottom: 4px;
}

.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  margin-top: auto;
}

.container {
  display: flex;
  align-items: center;
  margin-bottom: space(xxs);
}

.avatar {
  width: rem(29px);
  height: rem(29px);
  border-radius: 50%;
  overflow: hidden;
  background-color: color(tertiary);
  margin-right: space(xs);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.editor {
  font-size: font-size(md);
  font-weight: font-weight(semi);
  color: color(secondary);
}

.time {
  font-size: font-size();
  color: color(tertiary, dark);
  margin-top: auto;
}

.dot {
  width: 3px;
  height: 3px;
  background-color: color(tertiary, dark);
  display: inline-block;
  margin: 0 space(xxs);
  border-radius: 50%;
}
