@import 'assets/styles/variables';

.row {
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: 'col1' 'col2';

  @include media-breakpoint-up(md) {
    grid-template-areas: 'col1 col2';
    gap: space(xxl);
  }
}

.imageStart {
  @include media-breakpoint-up(md) {
    grid-template-areas: 'col2 col1';
  }
}

.infoSection {
  grid-area: col1;
  align-self: start;
}

.profile {
  margin-bottom: space(xs);
}

.nameContent {
  /* Content components set a fixed margin on anchor tags, which need to be removed for the name heading, otherwise the alignment doesn't fit */
  a {
    margin: 0;
  }
}

.name {
  margin: 0;
}

.jobTitle {
  font-size: font-size(lg);
  color: color(secondary);

  &.contrast {
    color: color(secondary, contrast);
  }
}

.topics {
  margin-top: space(xs);
}

.socialMenu {
  margin-top: space(md);
}

.imageSection {
  grid-area: col2;
  align-self: start;
}

.profilePicture {
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: rem(433px);
    object-fit: cover;
  }
}

.contrast {
  color: color(secondary, contrast);
}
