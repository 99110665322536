@import 'assets/styles/variables';

.wienEnergieBox {
  width: 217px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 0 5px 15px -5px rgb(0 0 0 / 35%);
  background-color: color(primary);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: translate(-10px, -35%);
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(md) {
    transform: translate(-25px, -35%);
  }
}

.dateBox {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  box-shadow: 0 22px 45px 0 rgb(0 0 0 / 11%);
  background-color: color(secondary);
  color: color(secondary, contrast);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(md) {
    transform: translate(32px, -50%);
  }
}

.dateDayContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: space(xxxs);
}

.day {
  display: block;
  font-size: font-size(xxl);
  font-weight: font-weight(semi);
  line-height: 1.14;
}

.month {
  display: block;
  font-size: font-size(md);
  font-weight: font-weight();
}

.card {
  margin-top: space(xl);

  .cardBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: space(xl);

    @include media-breakpoint-up(md) {
      padding-top: space(xxl);
    }

    .description {
      white-space: break-spaces;
      margin-bottom: space(md);

      @include limitLines(3);
    }
  }
}

.title {
  margin-bottom: space(xxs);
}

.infoContainer {
  margin-top: auto;
}

.dateContainer,
.locationContainer {
  display: flex;
  align-items: center;
  margin-bottom: space(sm);
}

.icon {
  color: color(secondary);
  font-size: font-size(xl);
  margin-right: space(xxs);
  align-self: start;
}

.infoTag {
  display: inline-block;
  font-size: font-size(lg);
  font-weight: font-weight(semi);
  line-height: 0;
}
