@import 'assets/styles/variables';

.socialMenu {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;

  &.start {
    justify-content: start;
  }

  &.end {
    justify-content: end;
  }
}

.socialMenuLink {
  padding: space(sm);

  &.dense {
    padding: space(xs);
  }
}

.socialMenuIcon {
  font-size: font-size(xl);
  color: color(secondary);

  &.contrast {
    color: color(secondary, contrast);
  }
}
