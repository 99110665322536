@import 'assets/styles/variables';

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: space();
  grid-template-rows: masonry;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.tweet {
  display: flex;
  flex-direction: column;
}

.avatar {
  display: flex;
}

.name {
  color: color(secondary);
  font-weight: font-weight(bold);
}

.avatarImage {
  margin-right: space(xs);
  border-radius: 50%;
}

.content {
  flex-grow: 1;
  margin-top: space(sm);
}

.tweetIcon {
  height: 24px !important;
  color: #55a1eb;
  width: 28px !important;
}

.meta {
  display: flex;
  justify-content: space-between;
  margin-top: space();
  margin-bottom: -#{space(sm)};
}

.metaIcon {
  margin-right: space(xxs);
}

.tweetLink {
  flex-grow: 1;
  justify-self: flex-end;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
