@import 'assets/styles/variables';

.eventCalendar {
  display: grid;
  gap: space(lg);
  grid-template-columns: auto;
  grid-template-rows: auto;

  @include media-breakpoint-up(md) {
    gap: space(lg);
    grid-template-rows: auto;
    row-gap: space(xxl);
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    column-gap: space(lg);
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
  }
}
