@use '@components/assets/styles/variables' as *;

.heading {
  margin: space(xs) 0 !important;
}

.floatingButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 72px;
  height: 72px;
  background-color: color(primary, light);
  background-image: linear-gradient(to bottom, #ed9e1e, #ed771e);
  border: 0;
  border-radius: 50%;
  color: color(secondary, contrast);
  z-index: #{z-index(backdrop, 4)};
  box-shadow: 0 4px 11px 0 rgb(0 0 0 / 25%);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: font-size(sm);
  outline: 0;

  &:focus {
    @include focus();

    &::after {
      border-radius: 50%;
      margin: -5px;
    }
  }
}

.floatingButtonText {
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  line-height: 1.3;
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
  transform: scale(0.5);

  &.visible {
    height: auto;
    width: auto;
    visibility: visible;
    transition:
      opacity 300ms ease-in-out,
      transform 300ms ease-in-out;
    opacity: 1;
    transform: scale(1);
  }
}

.closeIcon {
  opacity: 0 !important;
  visibility: hidden !important;
  height: 0 !important;
  width: 0 !important;
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
  transform: rotate(45deg) scale(0.5);

  &.visible {
    height: rem(28px) !important;
    width: rem(28px) !important;
    visibility: visible !important;
    opacity: 1 !important;
    transition:
      opacity 300ms ease-in-out,
      transform 300ms ease-in-out;
    transform: rotate(0) scale(1);
  }
}

.container {
  position: fixed;
  inset: 0;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.6s ease-in-out;
  transform: translate3d(0, 20px, 0);
  z-index: z-index(backdrop, 3);
  background-color: color(background);
  padding: space();
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: rem(100px);

  @include scrollbar();

  @include media-breakpoint-up(md) {
    overflow-y: auto;
    inset: auto 20px 100px auto;
    width: rem(500px);
    padding: space();
    max-height: 80vh;
    box-shadow: 0 22px 45px 0 rgb(0 0 0 / 30%);
  }
}

.container[data-enter] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.container[data-leave] {
  opacity: 0;
}

.callToAction {
  padding: space(sm) !important;
  margin-bottom: space(xs) !important;
  text-align: left !important;
  height: rem(60px) !important;

  @include media-breakpoint-up(md) {
    height: auto;

    br {
      display: none;
    }
  }
}

.button {
  width: 100%;
  margin-bottom: space();
}

.stars {
  margin-bottom: space(md);
}
