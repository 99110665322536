.noMargin {
  margin: 0;
}

.center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
